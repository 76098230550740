import React from 'react';
import raw from "./app-ads.txt"

const AppAds = () => {
  
  const [appAds, setAppAds] = React.useState('')

  React.useEffect(() => {
    fetch(raw)
    .then(r => r.text())
    .then(text => {
     // console.log('text decoded:', text);
      setAppAds(text)
    });
  }, [])

  return (
    <pre style={{wordWrap: "break-word", whiteSpace: "pre-wrap"}} >
        {appAds}
    </pre>
);

}
export default AppAds;
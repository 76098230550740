import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Find a perfect role',
    paragraph: 'We’re looking for talented creatives from all disciplines. Our team is focused on developing beautiful, functional games. We’d love you to join us.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="">
                  <h3>Unity Developer</h3>
                  <p className="text-sm mb-0">
                      Implement Unity-based UI, gameplay, tooling, streamline prefabs structure, implement a new gameplay feature.
                      </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
               {/* { <span className="testimonial-item-name text-color-high">Roman Level</span>} */}
                  <span className="testimonial-item-link">
                    <a href={`mailto:${"info@champygames.com"}`}>- Apply online</a>
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="">
                <h3>3D Artist</h3>

                  <p className="text-sm mb-0">
                    Create original and imaginative concept art for characters, environments, and other game assets.
                      </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-link">
                  <a href="https://www.linkedin.com/jobs/view/2350980610/">- Apply online</a>
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="">
                <h3>Game Designer</h3>

                  <p className="text-sm mb-0">
                    Translate designs into reality. Invent new ways to solve design problems while building complex UIs.
                      </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
         
                  <span className="testimonial-item-link">
                    <a href={`mailto:${"info@champygames.com"}`}>- Apply online</a>
                  </span>
                </div>
              </div>
            </div>
{
  /*
  <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="">
                  <h3>Senior Unity Developer</h3>
                  <p className="text-sm mb-0">
                      Implement Unity-based UI, gameplay, tooling, streamline prefabs structure, implement a new gameplay feature.
                      </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-link">
                    <a href="#0">- Apply online</a>
                  </span>
                </div>
              </div>
            </div>

             <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="">
                <h3>Senior 3D Artist</h3>

                  <p className="text-sm mb-0">
                    Create original and imaginative concept art for characters, environments, and other game assets.
                      </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-link">
                  <a href="#0">- Apply online</a>
                  </span>
                </div>
              </div>
            </div>
  */
}
            

           


          </div>
        </div>
      </div>
    </section>
  );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;
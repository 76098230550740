import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image  from '../elements/Image';
import LinkedInCertificate from '../elements/LinkedInCertificate';
import GoogleMap from 'google-map-react'
const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Team Members',
    paragraph: ''
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/MehmetCanErtuzun.jpg')}
                      alt="Features tile icon 01"
                      width={128}
                      height={128}
                      overflow= "hidden"style={{border: '2px solid white',borderRadius: '100px'}}/>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Mehmet Can Ertuzun
                    </h4>
                  <p className="m-0 text-sm">
                    Co-Founder, Game Developer
                    </p>
                    <LinkedInCertificate
                    lang="en_US"
                    certificationName="mcertuzun/"
                  />
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/efeli.jpg')}
                      alt="Features tile icon 02"
                      width={128}
                      height={128} style={{border: '2px solid white',borderRadius: '100px'}}/>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Ahmet Efe Erkal
                    </h4>
                  <p className="m-0 text-sm">
                    Co-Founder, Game Developer
                    </p>
                    <LinkedInCertificate
                    lang="en_US"
                    certificationName="ahmet-efe-erkal-027962168/"
                  />
                </div>
              </div>
            </div>

             {//Sercan Avci
            }
            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/SercanAvci.jpg')}
                      alt="Features tile icon 02"
                      width={128}
                      height={128} style={{border: '2px solid white',borderRadius: '100px'}}/>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                  Sercan Avci                    
                  </h4>
                  <p className="m-0 text-sm">
                    Game Developer
                    </p>
                    <LinkedInCertificate
                    lang="en_US"
                    certificationName="Sercan-avci-19a1291a5/"
                  />
                </div>
              </div>
            </div>

            {//Buse Kecici
            }
            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/BuseGursel.jpg')}
                      alt="Features tile icon 02"
                      width={128}
                      height={128} style={{border: '2px solid white',borderRadius: '100px'}}/>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Buse Gursel
                    </h4>
                  <p className="m-0 text-sm">
                    3D Artist
                    </p>
                    <LinkedInCertificate
                    lang="en_US"
                    certificationName="buse-keçici-67b52b107/"
                  />
                </div>
              </div>
            </div>

             {//Ipek Turan
            }
            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/IpekTuran.jpg')}
                      alt="Features tile icon 02"
                      width={128}
                      height={128} style={{border: '2px solid white',borderRadius: '100px'}}/>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Ipek Turan
                    </h4>
                  <p className="m-0 text-sm">
                    3D Artist
                    </p>
                    <LinkedInCertificate
                    lang="en_US"
                    certificationName="ipek-turan-2ab961163/"
                  />
                </div>
              </div>
            </div>

             {//Aysenur Aktas
            }
            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/AysenurAktas.jpg')}
                      alt="Features tile icon 02"
                      width={128}
                      height={128} style={{border: '2px solid white',borderRadius: '100px'}}/>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Aysenur Aktas
                    </h4>
                  <p className="m-0 text-sm">
                    Concept Artist
                    </p>
                    <LinkedInCertificate
                    lang="en_US"
                    certificationName="ayşenur-aktaş-627403203/"
                  />
                </div>
              </div>
            </div>
             {//Salih Berk Sahin
            }
            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/SalihBerkSahin.png')}
                      alt="Features tile icon 02"
                      width={128}
                      height={128} style={{border: '2px solid white',borderRadius: '100px'}}/>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                  Salih Berk Sahin  
                  </h4>
                  <p className="m-0 text-sm">
                    Game Developer
                    </p>
                    <LinkedInCertificate
                    lang="en_US"
                    certificationName="salih-berk-şahin-7ba251207/"
                  />
                </div>
              </div>
            </div>

            {//Selim Sehriyaroglu
            }
            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/SelimSehriyaroglu.jpg')}
                      alt="Features tile icon 02"
                      width={128}
                      height={128} style={{border: '2px solid white',borderRadius: '100px'}}/>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Selim Sehriyaroglu
                    </h4>
                  <p className="m-0 text-sm">
                    Game Developer
                    </p>
                    <LinkedInCertificate
                    lang="en_US"
                    certificationName="selimsehriyar/"
                  />
                </div>
              </div>
            </div>
                 {//Ali Turan Ayaz
            }
            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/AliTuranAyaz.jpg')}
                      alt="Features tile icon 02"
                      width={128}
                      height={128} style={{border: '2px solid white',borderRadius: '100px'}}/>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                  Ali Turan Ayaz
                    </h4>
                  <p className="m-0 text-sm">
                    Product Manager
                    </p>
                    <LinkedInCertificate
                    lang="en_US"
                    certificationName="ali-turan-ayaz-50b255114/"
                  />
                </div>
              </div>
            </div>
                 {//Alp Erken
            }
            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/AlpErken.JPG')}
                      alt="Features tile icon 02"
                      width={128}
                      height={128} style={{border: '2px solid white',borderRadius: '100px'}}/>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Alp Erken
                    </h4>
                  <p className="m-0 text-sm">
                    Game Designer
                    </p>
                    <LinkedInCertificate
                    lang="en_US"
                    certificationName="erkenalp/"
                  />
                </div>
              </div>
            </div>
            {/* {<div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-03.svg')}
                      alt="Features tile icon 03"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Robust Workflow
                    </h4>
                  <p className="m-0 text-sm">
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-04.svg')}
                      alt="Features tile icon 04"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Robust Workflow
                    </h4>
                  <p className="m-0 text-sm">
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-05.svg')}
                      alt="Features tile icon 05"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Robust Workflow
                    </h4>
                  <p className="m-0 text-sm">
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-06.svg')}
                      alt="Features tile icon 06"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Robust Workflow
                    </h4>
                  <p className="m-0 text-sm">
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat.
                    </p>
                </div>
              </div>
            </div>} */}

          </div>
        </div>
      </div>
      <div style={{ height: '100vh', width: '100%' }}>

      <GoogleMap/>

      </div>
    </section>
 
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
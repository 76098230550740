import React from 'react';
import PropTypes from 'prop-types';

const fileButton = 'https://content.linkedin.com/content/dam/me/business/en-us/amp/brand-site/v2/bg/LI-Logo.svg.original.svg';
const profileAdd = 'https://www.linkedin.com/in/';

const styles = {
  width: '120px',
  height: '24px',
};

const LinkedInCertificate = ({ lang, certificationName }) => (
  <a
    href={`${profileAdd}${certificationName}`}
    rel="noopener noreferrer"
    target="_blank"
  >
    <img
      src={`${fileButton}${lang}.png`}
    
      style={styles}
    />
  </a>
);

LinkedInCertificate.defaultProps = {
  lang: 'en_US',
  certificationName: '',
};

LinkedInCertificate.propTypes = {
  lang: PropTypes.string,
  certificationName: PropTypes.string,
};

export default LinkedInCertificate;